import { LoadingOutlined } from "@ant-design/icons";
import { getAuthorizationHeader } from "@api/common-utils";
import { baseUrl } from "@api/const";
import { useTokensContext } from "@components/layout/main-layout/private-layout";
import ProjectsTable from "@components/pages/ExistingProject/components/ProjectsTable/ProjectsTable";
import { ContentToExport } from "@components/pages/project/select-function/which-to-do/select-one/project-name/important-project-info/gcf-congratulation/components/ContentToExport";
import { useStore } from "@stores/root-store";
import { handleExportToDoc } from "@utils/common-functions";
import { constRoute } from "@utils/route";
import { Spin } from "antd";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
//@ts-ignore
import { v4 as uuidv4 } from "uuid";
import exportDataIcon from "../../../assets/icons/download-data.svg";
import pencilIcon from "../../../assets/icons/edit-2.svg";
import trashIcon from "../../../assets/icons/trash-2.svg";
import ProjectDeleteModelData from "./projectDeleteModel";
import style from "./style.module.scss";

const ExistingProject = observer(() => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const email: string = localStorage.getItem("email");
  const { pathname } = useLocation();
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 16, color: "#121212" }} spin />
  );
  const {
    user: {
      loadGetExistingProject,
      projectDelete,
      loadGetCongratulationResults,
      getSingleProjectData,
      getLoadingExistingProject,
      getLoadingDeleteRecord,
      setProjectName,
      loadGetDraftResults,
      loadGetAdvisorResults,
      loadGetTheoryOfChangeResults,
      getUserType,
      getProjectReportPdf
    }
  } = useStore(null);

  const userType = getUserType;

  const { setTokens } = useTokensContext();

  const [openModel, setOpenModel] = useState(false);
  const [projectData, setProjectData] = useState(null);
  const [loadingProjectData, setLoadingProjectData] = useState(true);
  const [downloadLoading, setDownloadLoading] = useState(null);
  const [editLoading, setEditLoading] = useState("");
  const [downloadData, setDownloadData] = useState({
    sections: [],
    sectionDataHandler: null
  });

  const modifyProjectData = (result, type) => {
    const dummyArray = [];

    if (!result?.projects[type]) {
      return dummyArray
    }

    Object.keys(result?.projects[type])?.forEach(item => {
      dummyArray?.push({
        type,
        projectName: item,
        id: uuidv4(),
        status: result?.projects[type][item] || null
      });
    });
    return dummyArray;
  };

  const handleLoadProject = async payload => {
    setProjectData(null);
    setLoadingProjectData(true);
    const result = await loadGetExistingProject(navigate).catch(() => {
      navigate(constRoute.login);
    });

    if (!result) {
      return
    }
    console.log('Modify project data ', result)
    const conceptNote = modifyProjectData(result, "concept note")
      .map(el => {
        return { ...el, projectName: el.projectName.replace("`", "'").trim() };
      })
      .filter(el => el.projectName !== payload?.project_name);

    const noteEvaluator = modifyProjectData(result, "concept note evaluator")
      .map(el => {
        return { ...el, projectName: el.projectName.replace("`", "'").trim() };
      })
      .filter(el => el.projectName !== payload?.project_name);

    const advisor = modifyProjectData(result, "project structuring")
      .map(el => {
        return { ...el, projectName: el.projectName.replace("`", "'").trim() };
      })
      .filter(el => el.projectName !== payload?.project_name);

    const theoryOfChange = modifyProjectData(result, "theory of change")
      .map(el => {
        return { ...el, projectName: el.projectName.replace("`", "'").trim() };
      })
      .filter(el => el.projectName !== payload?.project_name);

    const rationalAdvisor = modifyProjectData(result, "climate rationale")
      .map(el => {
        return { ...el, projectName: el.projectName.replace("`", "'").trim() };
      })
      .filter(el => el.projectName !== payload?.project_name);

    setProjectData({
      conceptNote: conceptNote,
      grading: advisor,
      concept_note_evaluator: noteEvaluator,
      project_structuring: advisor,
      theoryOfChange,
      rationalAdvisor
    });
    setLoadingProjectData(false);
  };

  const deleteProjectData = async () => {
    const payload = {
      project_name: data?.projectName,
      functionality: data?.type
    };
    const res = await projectDelete(payload, navigate);
    if (
      res?.error?.includes("Invalid token") ||
      res?.error?.includes("Token has expired")
    ) {
    } else {
      setOpenModel(false);
      await handleLoadProject(payload);
    }
  };

  function download(blob, filename) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  const handleDownloadDoc = async data => {
    const { projectName, type } = data;
    const name = { project_name: projectName };
    const filename =
      type === "concept note"
        ? "Concept Note Draft"
        : type === "project structuring"
          ? "Project Structuring"
          : type === "theory of change"
            ? type === "climate rationale"
              ? "Climate Rationale Advisor"
              : "Theory of Change"
            : "CN Evaluator";
    let result =
      type === "concept note"
        ? await loadGetDraftResults(name)
        : type === "project structuring"
          ? await loadGetAdvisorResults(name)
          : type === "theory of change"
            ? await loadGetTheoryOfChangeResults(name)
            : await loadGetCongratulationResults(name);
    const sectionDataHandler = item => {
      return result[item]?.length ? result[item] : "results...";
    };
    setDownloadData({ sections: Object.keys(result), sectionDataHandler });
    setTimeout(() => {
      handleExportToDoc(filename);
      setDownloadLoading(null);
    }, 100);
  };

  const onExportPdf = async data => {
    const { projectName, type } = data;
    console.log(type);
    const name = { project_name: projectName };

    const res = await getProjectReportPdf({
      project_name: projectName,
      email,
      functionality: type?.includes("theory of change")
        ? "theory of change"
        : type?.includes("evaluator")
          ? "concept note evaluator"
          : type === "concept note"
            ? "concept note"
            : type?.includes("climate rationale")
              ? "climate rationale"
              : "project structuring"
    });

    const url = URL.createObjectURL(res);

    // Create a link element
    const link = document.createElement("a");

    // Set the href and download attributes of the link
    link.href = url;
    link.download = "report.pdf"; // Set your desired file name here

    // Append the link to the body
    document.body.appendChild(link);

    // Programmatically click the link to download the file
    link.click();

    // Remove the link from the body
    document.body.removeChild(link);
  };

  const handleGenerateReport = async data => {
    const payload = {
      project_name: data?.projectName,
      functionality: "concept note"
    };
    await fetch(`${baseUrl}/generateReport`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorizationHeader()
      },
      body: JSON.stringify(payload)
    }).then(response => {
      response.blob().then(blob => download(blob, "project"));
    });

    // await generateReport(payload, navigate).then(response => {response.blob().then(blob => download(blob, 'project'))});
    // const blob = new Blob([result], { type: "application/pdf" });
    // const link = document.createElement("a");
    // link.href = window.URL.createObjectURL(blob);
    // link.download = "project.pdf";
    // document.body.appendChild(link);
    // link.click();
    setDownloadLoading(null);
  };
  useEffect(() => {
    handleLoadProject(null);
  }, []);

  const routeHandler = item => {
    const status = +item?.status[0]?.replaceAll("%", "") || 0;

    if (status >= 90) {
      return navigate(constRoute?.sustainabilityReplicabilityForm90, {
        state: { projectName: item?.projectName, isEdit: true }
      });
    } else if (status > 72) {
      return navigate(constRoute?.gcfJustificationForm72, {
        state: { projectName: item?.projectName, isEdit: true }
      });
    } else if (status > 64) {
      return navigate(constRoute?.ndaAe64Form, {
        state: { projectName: item?.projectName, isEdit: true }
      });
    } else if (status > 56) {
      return navigate(constRoute?.projectResultsGcfForm56, {
        state: { projectName: item?.projectName, isEdit: true }
      });
    } else if (status > 48) {
      return navigate(constRoute?.projectResultsGcfForm48, {
        state: { projectName: item?.projectName, isEdit: true }
      });
    } else if (status > 40) {
      return navigate(constRoute?.projectResultsGcfForm40, {
        state: { projectName: item?.projectName, isEdit: true }
      });
    } else if (status > 32) {
      return navigate(constRoute?.projectResultsGcfForm32, {
        state: { projectName: item?.projectName, isEdit: true }
      });
    } else if (status > 24) {
      return navigate(constRoute?.projectResultsGcfForm24, {
        state: { projectName: item?.projectName, isEdit: true }
      });
    } else if (status > 16) {
      return navigate(constRoute?.projectResultsGcfForm, {
        state: { projectName: item?.projectName, isEdit: true }
      });
    } else if (status > 8) {
      return navigate(constRoute?.projectDescriptionForm, {
        state: { projectName: item?.projectName, isEdit: true }
      });
    } else if (status === 0 || status <= 8) {
      return navigate(constRoute?.contextAndBaselineForm, {
        state: { projectName: item?.projectName, isEdit: true }
      });
    } else {
      return navigate(constRoute?.contextAndBaselineForm, {
        state: { projectName: item?.projectName, isEdit: true }
      });
    }
  };

  const handleGetData = async item => {
    setProjectName(item?.projectName);
    const payload = {
      project_name: item?.projectName,
      section: item?.type?.includes("evaluator")
        ? "B_1"
        : item?.type?.includes("project structuring")
          ? "pis"
          : item?.type?.includes("theory of change")
            ? "GSR"
            : item?.type?.includes("climate rationale")
              ? "i_24"
              : "B_1",
      functionality: item?.type
    };
    localStorage.setItem("data-for-tokens", JSON.stringify(payload));
    !item?.type?.includes("structuring") &&
      (await getSingleProjectData(payload, navigate).then(res => {
        setTokens(res["tokens_remaining/tokens_purchased"]);
      }));
    setEditLoading("");

    const evaluate = [
      "0%",
      "8%",
      "14%",
      "21%",
      "29%",
      "34%",
      "43%",
      "50%",
      "57%",
      "64%",
      "71%",
      "78%",
      "85%",
      "92%",
      "100%"
    ];
    const rational_advisor = ["24%", "48%", "64%", "76%", "84%", "100%"];
    const advisor = ["0%", "20%", "40%", "60%", "80%", "100%"];
    const toc = ["0%", "24%", "48%", "64%", "76%", "100%"];

    switch (item.type) {
      case "concept note evaluator":
        localStorage.setItem("projectName", item?.projectName);
        navigate(
          constRoute?.gradeProject +
            `/form/${Math.max(evaluate.indexOf(item.status[0] || "0%"), 1)}`
        );
        break;
      case "project structuring":
        localStorage.setItem("projectName", item?.projectName);
        navigate(
          constRoute?.advisorProject +
            `/form/${advisor.indexOf(item.status[0] || "0%") + 1}`
        );
        break;
      case "concept note":
        localStorage.setItem("projectName", item?.projectName);
        navigate(
          constRoute?.draftProject +
            `/form/${Math.max(evaluate.indexOf(item.status[0] || "0%"), 1)}`
        );
        break;

      case "climate rationale":
        localStorage.setItem("projectName", item?.projectName);
        navigate(
          constRoute?.rationalAdvisorProject +
            `/form/${Math.max(rational_advisor.indexOf(item.status[0] || "0%") + 1, 1)}`
        );
        localStorage.setItem("currentPage", "/home");
        break;

      case "theory of change":
        localStorage.setItem("projectName", item?.projectName);
        navigate(
          constRoute?.theoryOfChangeProject +
            `/form/${Math.max(toc.indexOf(item.status[0] || "0%"), 1)}`
        );
        break;
    }
  };

  const actions = data => {
    const type = data.type;

    return (
      <div className={style.flexWrapper}>
        {editLoading === data?.projectName ? (
          <Spin indicator={antIcon} />
        ) : (
          <img
            src={pencilIcon}
            className={style.imgClass}
            onClick={() => {
              if (
                userType !== "enterprise" &&
                (type === "climate rationale" || type === "grading")
              )
                return;
              setEditLoading(data?.projectName);
              handleGetData(data);
              localStorage.removeItem("projectSector");
              // navigate(constRoute?.contextAndBaselineForm, { state: { projectName: data?.projectName, isEdit: true} })
            }}
          />
        )}
        <img
          src={trashIcon}
          className={style.imgClass}
          onClick={() => {
            if (
              userType !== "enterprise" &&
              (type === "climate rationale" || type === "grading")
            )
              return;
            setData(data);
            setOpenModel(true);
          }}
        />
        <>
          {downloadLoading?.id === data?.id &&
          downloadLoading?.type === "pdf" ? (
            <Spin indicator={antIcon} />
          ) : (
            <></>
          )}
          {downloadLoading?.id === data?.id &&
          downloadLoading?.type === "docs" ? (
            <Spin indicator={antIcon} />
          ) : (
            <img
              style={{
                pointerEvents:
                  downloadLoading === data?.projectName ? "none" : "auto"
              }}
              src={exportDataIcon}
              className={style.imgClass}
              onClick={() => {
                onExportPdf(data);
              }}
            />
          )}
        </>
      </div>
    );
  };

  return (
    <>
      <div className={style.homePagePageContainer}>
        <div>
          <div className={style.projectHeading}>Projects</div>
          <p className={style.firstdivaragraph}>
            Find all the project applications you’re overseeing below.
          </p>
          {/* <p className={style.secondParahGraph}>
                        Draft a GCF Concept Note
          </p>
          <div className={style.responsiveTable}>
            <Table
              dataSource={projectData?.grading || []}
              className={style.tableStyle}
              columns={columns}
              loading={getLoadingExistingProject}
            />
          </div> */}
          {/*<p className={style.thirdPara}>Draft a GCF Concept Note</p>*/}
          {/*<div className={style.responsiveTable}>*/}
          {/*  <Table*/}
          {/*    dataSource={projectData?.conceptNote.reverse() || []}*/}
          {/*    className={style.tableStyle}*/}
          {/*    columns={columns}*/}
          {/*    loading={getLoadingExistingProject}*/}
          {/*    pagination={false}*/}
          {/*  />*/}
          {/*</div>*/}
          {/*<p className={style.thirdPara}>Evaluate a Concept Note</p>*/}
          {/*<div className={style.responsiveTable}>*/}
          {/*  <Table*/}
          {/*    dataSource={projectData?.concept_note_evaluator || []}*/}
          {/*    className={style.tableStyle}*/}
          {/*    columns={columns}*/}
          {/*    loading={getLoadingExistingProject}*/}
          {/*    pagination={false}*/}
          {/*  />*/}
          {/*</div>*/}

          <ProjectsTable
            data={projectData?.concept_note_evaluator || []}
            title="Evaluate a Concept Note"
            actions={data => actions(data)}
            loadingProjectData={loadingProjectData}
            setEditLoading={data => setEditLoading(data)}
            handleGetData={data => handleGetData(data)}
            fileIconColor="#00B840"
            userType={userType}
          />

          <ProjectsTable
            data={projectData?.conceptNote || []}
            title="Draft a GCF Concept Note"
            actions={data => actions(data)}
            loadingProjectData={loadingProjectData}
            setEditLoading={data => setEditLoading(data)}
            handleGetData={data => handleGetData(data)}
            fileIconColor="#244A32"
            userType={userType}
          />

          <ProjectsTable
            data={projectData?.theoryOfChange || []}
            title="Theory of Change"
            actions={data => actions(data)}
            loadingProjectData={loadingProjectData}
            setEditLoading={data => setEditLoading(data)}
            handleGetData={data => handleGetData(data)}
            fileIconColor="#418F5C"
            userType={userType}
          />

          <ProjectsTable
            data={projectData?.project_structuring || []}
            title="Renewable Energy Project Structuring"
            actions={data => actions(data)}
            loadingProjectData={loadingProjectData}
            setEditLoading={data => setEditLoading(data)}
            handleGetData={data => handleGetData(data)}
            fileIconColor="#1A9244"
            userType={userType}
          />

          {userType === "enterprise" && (
            <ProjectsTable
              data={projectData?.rationalAdvisor || []}
              title="Climate Rationale Advisor"
              actions={data => actions(data)}
              loadingProjectData={loadingProjectData}
              setEditLoading={data => setEditLoading(data)}
              handleGetData={data => handleGetData(data)}
              fileIconColor="#1A9244"
              userType={userType}
            />
          )}

          {/*<p className={style.thirdPara}>Project Structuring Advisor</p>*/}
          {/*<div className={style.responsiveTable}>*/}
          {/*  <Table*/}
          {/*    dataSource={projectData?.project_structuring || []}*/}
          {/*    className={style.tableStyle}*/}
          {/*    columns={columns}*/}
          {/*    loading={getLoadingExistingProject}*/}
          {/*    pagination={false}*/}
          {/*  />*/}
          {/*</div>*/}
          {/* <p className={style.lastPara}>Climate Rationale Advisor</p> */}
        </div>
        <ProjectDeleteModelData
          loading={getLoadingDeleteRecord}
          isOpen={openModel}
          closeModal={() => setOpenModel(false)}
          onDeleteRecord={deleteProjectData}
        />
      </div>
      <div
        id="exportContent"
        style={{ visibility: "hidden", position: "absolute", zIndex: -1 }}
      >
        <ContentToExport
          sections={downloadData?.sections}
          sectionDataHandler={downloadData?.sectionDataHandler}
        />
      </div>
    </>
  );
});

export default ExistingProject;
function Actions(props) {
  return <div {...props}>{props.actions}</div>;
}
