import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import {
  useNarrativesContext,
  useTokensContext
} from "@components/layout/main-layout/private-layout";
import AdvisorForm from "@components/pages/project/common-components/advisor-form";
import CommonFooterButton from "@components/pages/project/select-function/which-to-do/select-one/project-name/important-project-info/commonfooterbutton";
import { useStore } from "@stores/root-store";
import { constRoute } from "@utils/route";
import { useForm } from "antd/es/form/Form";
import { useNavigate, useParams } from "react-router-dom";
import { io } from "socket.io-client";
import { DataType } from "types/data-type";
import { baseUrl } from "@api/const";

interface AdvisorFormProps {}
const formQuestions = [
  {
    q: <span>Enter the Project’s Goal Statement Below:</span>,
    result: <span>Your Results Below:</span>,
    limit: 1000,
    placeholder:
      "Guidance ​Below: \n\n" +
      'Goal Statement Structure​: When crafting the goal statement, adhere to the "IF... THEN... BECAUSE..." format to elucidate the causal relationships between project outputs, outcomes, and the goal. \n\n' +
      "Climate Rationale and Context: When discussing the climate rationale and context, ensure that the narrative meets the following criteria: \n\n" +
      "The project's relevance to climate change should be clearly articulated, explaining how the proposed project or program addresses climate change challenges. \nThe long-term vision or ultimate goal of the project should be outlined, aligning it with GCF objectives and priorities and demonstrating a robust connection to climate change mitigation or adaptation efforts. \n\n" +
      "Specific and measurable objectives should be offered, enabling clear assessment of progress and impact. This includes concrete indicators that can be tracked and evaluated. \n\n" +
      "Finally, the narrative should take into account the involvement of relevant stakeholders, including local communities. "
  },
  {
    q: <>Enter  Project’s Goal, Outcomes, Components and Outputs Below:</>,
    result: <>Your Results Below:</>,
    limit: 1000,
    placeholder:
      "Guidance ​Below: \n\n" +
      "Long Term Vision Evaluation:  Ensure that the narrative clearly states the long-term vision or goal of the project. This should include a specific statement about the long-term impact the project aims to achieve, explaining how it contributes to a shift towards a low carbon and/or climate-resilient development pathway.\n\n" +
      'Pathway of Change​: When outlining the pathway of change, include a detailed description of the cause-and-effect relationships between the long-term vision and outcomes. This should answer the question, "What outcomes need to be in place for the project\'s long-term vision to happen?" in detail.\n\n' +
      "Consistency with National Frameworks​: When discussing consistency with national frameworks, ensure that the narrative describes how activities in the proposal are consistent with national regulatory and legal frameworks. This should include an indication of how the project fits in with the country’s national priorities and its full ownership of the concept."
  },
  {
    q: <>Enter the Project’s Identified Barriers and Risks Below:</>,
    result: <>Your Results Below:</>,
    placeholder:
      "Guidance ​Below:\n\n" +
      "Barriers Review​: Be sure that your narrative discusses the barriers that may impede the project's successful implementation and hinder the desired sector transformation. This should include identifying the barriers that may threaten the achievement of the project’s activities.\n\n" +
      "Risks Review​: Be sure that your narrative discusses all the risks that may impede the project's successful implementation and hinder the desired sector transformation. This should include identifying the risks that may threaten the achievement of the project’s activities. Risks to consider include financial, technical, operational, macroeconomic/political, money laundering/terrorist financing (ML/TF), sanctions, prohibited practices, and other risks that might prevent the project/programme objectives from being achieved.\n\n",
    limit: 1000
  },
  {
    q: <>Enter the Project Assumptions Below:</>,
    result: <>Your Results Below:</>,
    limit: 1000,
    placeholder:
      "Guidance ​B​elow: \n\n" +
      "Clarity of Assumptions​: Clearly identify and explain the assumptions made in the project. This includes positively-worded statements that are outside of the project's control and necessary for achieving the next level of outcomes.\n\n" +
      "Relation to Real World Testing: Clearly articulate the methods for testing these assumptions. This should include a description of how the project will validate its assumptions in the real world.\n\n" +
      "Cultural Sensitivity: Acknowledge that interventions may not work uniformly across different cultures or localities. This includes recognizing the potential for cultural differences to impact the project's success.\n\n" +
      "Problem Identification: Identify potential obstacles or barriers towards achieving objectives. This includes recognizing the potential risks and challenges that may arise during the project.\n\n" +
      "Flexibility in Approach: Mention the importance of revisiting and adjusting proposed interventions based on identified barriers. This includes recognizing the need for flexibility in the project's approach to overcome unexpected challenges.\n\n" +
      "Connection between Assumptions and Outcomes: Establish a clear link between the assumptions and the potential impact on outcomes. This includes explaining how the assumptions will influence the project's success.\n\n" +
      "Monitoring & Remediation: Emphasize the importance of active monitoring and corrective actions when assumptions fail. This includes recognizing the need for ongoing monitoring and the ability to adapt to changing circumstances.\n\n"
  },
  {
    q: <>Enter Project Objectives, Outcomes, Outputs and Activities below: </>,
    result: <>Your Results Below:</>,
    limit: 1000,
    placeholder: [
      "Be sure to enter Project/Programme Objective which should outline the overall goal or impact the project aims to achieve. IT should also contain the following:\n\n",
      "Outcomes: These are the specific, measurable changes or benefits that the project is expected to deliver. They should be directly linked to the project objective and contribute to its achievement.\n\n",
      "Outputs: These are the tangible products, services, or results that the project will produce in order to achieve the desired outcomes. Outputs should be clearly defined and measurable.\n\n"
    ].join(''),
  }
];


export const sectionsMap = [{
  text: "Goal Statement Review",
  section: 'GSR'
},{
  text: "Alignment Checker",
  section: 'AAC'
},{
  text: "Barriers and Risks",
  section: 'BR'
},{
  text: "Assumptions",
  section: 'A'
},{
  text: "Notional Log Frame",
  section: 'NLF'
}]

const sections = sectionsMap.map(section => section.section)
//const sections = ["GSR", "AAC", "BR", "A", "NLF"];

const TheoryOfChangePage: React.FC<AdvisorFormProps> = () => {
  const nav = useNavigate();
  const socketRef = useRef(null);
  const { type, step } = useParams();
  const [form] = useForm();
  const {
    user: { getSingleProjectData, projectNameData },
    modelType: { getSelectedModelType }
  } = useStore(null);
  const { setTokens } = useTokensContext();
  const { tocNarratives, setTocNarratives } = useNarrativesContext();
  const projectName =
    (projectNameData && JSON.parse(projectNameData).project_name) ||
    localStorage.getItem("projectName");

  const [initialInput, setInitialInput] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [resultsData, setResultsData] = useState("");

  const results = isSubmitting
    ? ""
    : tocNarratives.find(el => el.section === sections[+step - 1])?.res;

  const email = localStorage.getItem("email");
  const isFormPage = type === "form";

  const handleSubmit = useCallback(
    values => {
      if (!isValid) return;
      if (isFormPage) {
        setResultsData("");
        setIsSubmitting(true);

        const data: DataType = {
          section: sections[+step - 1],
          input: values?.q,
          project_name: projectName,
          email: email,
          model: getSelectedModelType ?? "Mentor"
        };

        console.log("payload body ------------->", data);

        const socket = io(baseUrl, {});

        socketRef.current = socket;
        socketRef?.current?.emit("theoryOfChange", data);
        socketRef?.current?.on("answer", answer => {
          const isStopGeneration = localStorage.getItem("isStopGeneration");

          if (!isSubmitting && answer) {
            setIsSubmitting(true);
          }

          if (isStopGeneration || answer === "#####") {
            socket.disconnect();
            setIsSubmitting(false);
            localStorage.removeItem("isStopGeneration");
            return;
          }
          form.setFieldValue("q", (form?.getFieldValue("q") ?? "") + answer);
          localStorage.setItem(
            "totalResultsAdvisor" + projectName,
            JSON.stringify({
              ...results,
              [`result${step}`]: (form?.getFieldValue("q") ?? "") + answer
            })
          );
        });
        socket.on("disconnect", () => {
          localStorage.removeItem("isStopGeneration");
          setIsSubmitting(false);
        });
        nav(constRoute?.theoryOfChangeProject + "/results/" + step);
      } else {
        if (formQuestions.length < +step + 1) {
          nav(constRoute?.gcfCongratulation + "/theory-of-change");
          return;
        }
        setIsSubmitting(false);
        nav(constRoute?.theoryOfChangeProject + "/form/" + (+step + 1));
      }
    },
    [type, step, getSelectedModelType]
  );

  const handleGoBack = () => {
    socketRef?.current?.disconnect();
    setIsSubmitting(false);
    // if (isSubmitting) return;
    if (+step === 1 && type == "results") {
      nav(constRoute?.theoryOfChangeProject + "/form/1");

      return;
    }

    if (+step < 2) {
      nav(constRoute.projectName + "/theory-of-change");
      return;
    }

    if (type === "results") {
      nav(constRoute?.theoryOfChangeProject + "/form/" + +step);
    } else {
      nav(constRoute?.theoryOfChangeProject + "/results/" + (+step - 1));
    }
  };

  const handleSaveQuit = () => {
    nav(constRoute?.existingProject);
  };

  const getProjectData = async () => {
    const email = localStorage.getItem("email");
    const data = {
      section: sections[+step - 1],
      project_name: projectName,
      functionality: "theory of change",
      email
    };
    setIsLoading(true);
    localStorage.setItem("data-for-tokens", JSON.stringify(data));
    await getSingleProjectData(data, null)
      .then(state => {
        setInitialInput(state?.questions);
        if (isFormPage) {
          form.setFieldValue("q", state?.questions.input);
        } else {
          if (!isSubmitting) {
            form.setFieldValue(`q`, state?.generated_narratives?.find(Boolean));
          }
        }
        setTokens(state["tokens_remaining/tokens_purchased"]);
        setTocNarratives(prev =>
          prev.map(el => {
            if (el.section === sections[+step - 1]) {
              return {
                ...el,
                res: state.generated_narratives.map(el => el).join("") || ""
              };
            } else {
              return el;
            }
          })
        );
      })
      .finally(() => setIsLoading(false));
    if (+step < 1 || formQuestions.length + 1 <= +step) {
      nav(constRoute?.gcfCongratulation + "/theory-of-change");
    }
  };

  const handleStopGenerate = () => {
    if (socketRef) {
      socketRef.current.disconnect();
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    localStorage.removeItem("isStopGeneration");
    getProjectData();

    // return () => {
    //   localStorage.removeItem("isStopGeneration");
    // };
  }, [step, type]);

  useEffect(() => {
    form.resetFields();
  }, [type === "results"]);

  useEffect(() => {
    socketRef?.current?.disconnect();
    form.resetFields();
    setIsSubmitting(false);
    setResultsData(
      tocNarratives.find(el => el.section === sections[+step - 1])?.res || ""
    );
  }, [step]);

  useEffect(() => {
    return () => {
      socketRef?.current?.disconnect();
    };
  }, []);

  return (
    <div style={{ padding: "30px 52px 122px" }}>
      <AdvisorForm
        form={form}
        value={results}
        isSubmitting={isSubmitting}
        initialState={initialInput}
        handleSubmit={handleSubmit}
        isFormPage={isFormPage}
        Text={
          isFormPage
            ? formQuestions[+step - 1]?.q
            : formQuestions[+step - 1]?.result || results[`result${step}`] || ""
        }
        placeholder={formQuestions[+step - 1]?.placeholder}
        maxLength={formQuestions[+step - 1]?.limit}
        disabled={type === "results"}
        isItalicFontStyle
        section={sections[+step - 1]}
        setIsValid={setIsValid}
        isValid={isValid}
        handleStopGenerate={handleStopGenerate}
        isLoading={isLoading}
      />
      <CommonFooterButton
        customStyle={{ left: 0 }}
        isLoadingSubmit={false}
        handleSubmit={handleSubmit}
        handlegoback={handleGoBack}
        isSubmitting={isSubmitting}
        handleSaveAndQuit={handleSaveQuit}
        isSubmit={type === "results"}
        // handleQuickNext={constRoute?.contextAndBaselineResults}
        form={form}
        setIsSubmitting={setIsSubmitting}
        disableSubmit={!isValid || isLoading}
      />
    </div>
  );
};

export default memo(observer(TheoryOfChangePage));
