import React, { memo, useEffect, useState } from 'react';

import { Form, Select, Upload } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import classNames from 'classnames';

import ArrowIconWhite from '@assets/icons/ArrowIconWhite';
import StopGenerationIcon from '@assets/icons/StopGenerationIcon';
import Copied from '@assets/icons/copied.svg';
import Copy from '@assets/icons/copy.svg';
import CopyIcon from '@assets/playground/icon_copy.svg';
import RegenerateIcon from '@assets/playground/icon_regenerate.svg';
import Close from '@assets/icons/cross.svg';
import down from '@assets/images/down.svg';
import Spinner from '@assets/icons/spinner.svg';
import UploadImage from '@assets/playground/icon_upload_image.svg';
import UploadDocument from '@assets/playground/icon_upload_doc.svg';
import ChatAIOutputIcon from '@assets/icons/chat_ai_output.png';
import IconAdd from '@assets/playground/icon_add.svg';
import { ChatRole, IChat } from '@components/pages/project/Playground/types';
import MarkdownViewer, { processMarkdown } from '@components/common-components/markdown-viewer';
import { useStore } from '@stores/root-store';
import { validateMessages } from '@utils/json-data';
import pdfButtonIcon from '../../../../assets/icons/writing-tips-button.svg';
import style from './style.module.scss';

interface AdvisorFormProps {
  Text: JSX.Element;
  disabled: boolean;
  maxLength: number;
  handleSubmit: (data: any) => void;
  handleRegenerate: () => void;
  onDownloadPdf?: () => void;
  setInput?: (val: string) => void;
  setChat?: any;
  chat: IChat[];
  research: string;
  setResearch: (val: string) => void;
  scoring: string;
  setScoring: (val: string) => void;
  form: any;
  initialState: any;
  selectedFile?: File | null;
  setSelectedFile?: (file: File) => void;
  selectedDocument?: File | null;
  setSelectedDocument?: (file: File) => void;
  documentsMap: any,
  placeholder?: string;
  isFormPage?: boolean;
  isSubmitting?: boolean;
  value?: string;
  input?: string;
  question?: string;
  stopGeneration?: () => void;
}

const PlaygroundForm: React.FC<AdvisorFormProps> = ({
  Text,
  disabled,
  maxLength,
  handleSubmit,
  handleRegenerate,
  form,
  initialState,
  placeholder,
  isFormPage,
  value,
  isSubmitting,
  question,
  onDownloadPdf,
  input,
  selectedFile,
  setSelectedFile,
  selectedDocument,
  setSelectedDocument,
  documentsMap,
  setInput,
  chat,
  setChat,
  research,
  setResearch,
  scoring,
  setScoring,
  stopGeneration,
}) => {
  const {
    //user: { getUserType },
    modelType: { changeModelType, getSelectedModelType },
  } = useStore(null);
  const [copiedIndex, setCopiedIndex] = React.useState<number>(-1);
  const [preview, setPreview] = useState('');
  const [showRegenerate, setShowRegenerate] = useState(false);
  const getUserType = 'enterprise';

  const onClickEnter = (event) => {
    if (event.key === 'Enter' && input.trim().length) {
      event.preventDefault();
      handleSubmit('');
    }
  };

  useEffect(() => {
    form.setFieldValue('q', input);
  }, [input]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    const chat = document.getElementById('chat');
    chat.scrollTop = chat.scrollHeight;
  }, [chat]);

  useEffect(() => {
    if (getUserType === 'enterprise') {
      changeModelType('Writer');
    }
  }, [getUserType]);

  useEffect(() => {
    if (!copiedIndex) return;

    setTimeout(() => {
      setCopiedIndex(null);
    }, 2000);
  }, [copiedIndex]);

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const systemIcon = (
    <div className={style.chatMessageSystemAIIcon}>
      <img src={ChatAIOutputIcon} alt='AI' />
    </div>
  );

  return (
    <>
      <div className={style.formWrapper}>
        <Form
          className={style.form}
          form={form}
          autoComplete='false'
          onFinish={handleSubmit}
          validateMessages={validateMessages}
          layout='vertical'
          initialValues={{
            q: input,
          }}
        >
          <div
            className={style.wrapper}
            style={{
              maxHeight: selectedFile ? '50vh' : '60vh',
            }}
          >
            <div className={style.chat} id='chat'>
              <div
                key='initialMessage'
                className={[style.chatMessage, style.chatMessageSystem].join(
                  ' '
                )}
              >
                {systemIcon}{' '}
                <div className={style.chatMessageText}>
                  <p>{Text}</p>
                </div>
              </div>
              {!!chat.length
                ? chat.map((el: IChat, index: number) => {
                    return (
                      <div
                        key={index}
                        className={[
                          style.chatMessage,
                          el.role === ChatRole.CHAT
                            ? style.chatMessageSystem
                            : style.chatMessageUser,
                        ].join(' ')}
                      >
                        {el.role === ChatRole.CHAT ? systemIcon : null}
                        <div className={style.chatMessageText}>
                          {el.role === ChatRole.USER ? (
                            el.message
                          ) : (el.isLastChatMessage === true && el.message === '')
                            ? <div className={style.systemGeneratingResponse}>
                              <img src={Spinner} /> Generating a response
                            </div>
                            : <MarkdownViewer text={el.message}/>
                          }
                        </div>
                        {el.image && (
                          <img src={URL.createObjectURL(el.image)} alt='' />
                        )}
                        {documentsMap[el.id] && (
                          <div className={style.chatMessageDocument}>
                            Selected File: <b>{documentsMap[el.id].name}</b>
                          </div>
                        )}
                        <div className={style.chatMessageControls}>
                          <div
                            className={style.copyIcon}
                            onClick={async () => {
                              const { htmlContent, plainText } = await processMarkdown(el.message);
                              // Copy both HTML and plain text to the clipboard
                              await navigator.clipboard.write([
                                new ClipboardItem({
                                  'text/html': new Blob([htmlContent], { type: 'text/html' }),
                                  'text/plain': new Blob([plainText], { type: 'text/plain' }),
                                }),
                              ]);

                              setCopiedIndex(index);
                            }}
                          >
                            {el.role === ChatRole.CHAT &&
                              el.message &&
                              (copiedIndex === index ? (
                                <img
                                  style={{ width: '24px', height: '24px' }}
                                  src={Copied}
                                  alt='copied'
                                />
                              ) : (
                                <img
                                  style={{ cursor: 'pointer' }}
                                  src={CopyIcon}
                                  alt='copy'
                                />
                              ))}
                          </div>
                          { (el.isLastChatMessage && el.message) && <div
                            className={style.copyIcon}
                            onClick={async () => {
                              //setShowRegenerate(true)
                              handleRegenerate();
                            }}
                          >
                            <img
                                  style={{ cursor: 'pointer' }}
                                  src={RegenerateIcon}
                                  alt='Regenerate response'
                                />
                          </div>}
                          { (el.isLastChatMessage && el.message && showRegenerate) && <div
                            className={style.copyIcon}
                            style={{lineHeight: '16px'}}
                          >
                            Do you want to regenerate?
                            {' '} 
                            <a href="#" onClick={(e) => {e.preventDefault(); handleRegenerate(); setShowRegenerate(false);}}>yes</a>
                            {' / '}
                            <a href="#" onClick={(e) => {e.preventDefault(); setShowRegenerate(false)}}>no</a>
                          </div>}
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
            <div className={style.bottomSection}>
              <Select
                defaultValue={'Writer'}
                style={{ width: '150px', textAlign: 'start' }}
                suffixIcon={<img src={down} alt={'down icon'} />}
                onChange={(value) => changeModelType(value)}
                placement='topRight'
                options={[
                  {
                    label: 'Writer',
                    value: 'Writer',
                  },
                  {
                    label: 'Research',
                    value: 'Research',
                  },
                  {
                    label: 'Evaluation',
                    value: 'Evaluation',
                  },
                ]}
              />
              <Select
                style={{
                  marginLeft: '25px',
                  width: '221px',
                  textAlign: 'start',
                }}
                suffixIcon={<img src={down} alt={'down icon'} />}
                onChange={(value) => setResearch(value)}
                value={research}
                placement='topRight'
                options={[
                  {
                    label: 'Select Research',
                    value: '',
                  },
                  {
                    label: 'Jamaica Climate Science',
                    value: 'jamaica_climate_science',
                  },
                  {
                    label: 'Jamaica Climate Policy',
                    value: 'jamaica_climate_policy',
                  },
                ]}
              />
              <Select
                style={{
                  marginLeft: '25px',
                  width: '221px',
                  textAlign: 'start',
                }}
                suffixIcon={<img src={down} alt={'down icon'} />}
                onChange={(value) => setScoring(value)}
                value={scoring}
                placement='topRight'
                options={[
                  {
                    label: 'Select Scoring',
                    value: '',
                  },
                  {
                    label: 'iTAP Assessment',
                    value: 'gif_itap_assessment',
                  },
                ]}
              />
              <Select
                style={{
                  marginLeft: '25px',
                  width: '221px',
                  textAlign: 'start',
                }}
                suffixIcon={<img src={down} alt={'down icon'} />}
                onChange={(value) => setScoring(value)}
                disabled={true}
                value={'Select Adaptation Fund'}
                placement='topRight'
                options={[
                  {
                    label: 'Select Adaptation Fund',
                    value: '',
                  },
                ]}
              />
              <Select
                style={{
                  marginLeft: '25px',
                  width: '241px',
                  textAlign: 'start',
                }}
                suffixIcon={<img src={down} alt={'down icon'} />}
                onChange={(value) => setScoring(value)}
                disabled={true}
                value={'Select Institutional Database'}
                placement='topRight'
                options={[
                  {
                    label: 'Select Institutional Database',
                    value: '',
                  },
                ]}
              />
            </div>
          </div>
          <Form.Item
            name={'q'}
            rules={[{ required: !disabled, message: 'This field is required' }]}
          >
            <div
              style={{
                border: '1px solid #E6E6E6',
                borderRadius: '5px',
                background: '#fff',
                boxShadow: '0px 1px 4px 1px #0000000F',
                display: 'flex',
                alignItems: 'end',
                paddingTop: preview || selectedDocument ? '57px': undefined
              }}
            >
              <TextArea
                style={{ border: '0px', resize: 'none' }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    onClickEnter(e);
                  }
                }}
                placeholder='Enter your query here'
                // disabled={disabled}
                value={input}
                onChange={(e) => {
                  setInput(e.target.value);
                }}
                autoSize
                className={classNames(
                  style.textarea,
                  style.textareaFont,
                  isSubmitting && style.textareaWithoutItalicStyle
                )}
              />
              {preview && (
                <div className={style.previewWrapper}>
                  <div>
                    <img src={preview} alt='preview' />
                    <div
                      className={style.clearFile}
                      onClick={() => {
                        setSelectedFile(null);
                        setPreview('');
                      }}
                    >
                      <img src={Close} alt='Clear file' />
                    </div>
                  </div>
                </div>
              )}
              {selectedDocument && (
                <div className={style.previewWrapper}>
                  <div>
                    Selected File: {selectedDocument.name}
                    <div
                      className={style.clearFile}
                      onClick={() => {
                        setSelectedDocument(null);
                      }}
                    >
                      <img src={Close} alt='Clear file' />
                    </div>
                  </div>
                </div>
              )}
              <div className={style.textareaIcons}>
                <div className={style.textareaIconsAdd}>
                  <img className={style.icon} src={IconAdd} />
                  <div className={style.content}>
                    <Upload
                      accept='image/*'
                      onChange={(info) => {
                        setSelectedFile(info.file.originFileObj);
                      }}
                      itemRender={() => null}
                    >
                      <div className={style.contentUploadWrapper}>
                        <img src={UploadImage} alt='upload' />
                        <div className={style.description}>Upload image</div>
                      </div>
                    </Upload>
                    <Upload
                      accept='.pdf,.doc,.docx'
                      onChange={(info) => {
                        setSelectedDocument(info.file.originFileObj);
                      }}
                      itemRender={() => null}
                    >
                      <div className={style.contentUploadWrapper}>
                        <img src={UploadDocument} alt='upload document' />
                        <div className={style.description}>
                          Upload file
                          <br />
                          <span className={style.subDescription}>
                            Only .doc and pdfs
                          </span>
                        </div>
                      </div>
                    </Upload>
                  </div>
                </div>

                <div
                  className={style.textareaIcon}
                  onClick={handleSubmit}
                  style={{
                    backgroundColor: isSubmitting ? 'transparent' : '',
                    height: isSubmitting ? '35px' : undefined,
                    width: isSubmitting ? '35px' : undefined,
                  }}
                >
                  {isSubmitting ? (
                    <div
                      style={{ width: '35px', height: '35px' }}
                      onClick={() => stopGeneration()}
                    >
                      <StopGenerationIcon />
                    </div>
                  ) : (
                    <ArrowIconWhite />
                  )}
                </div>
              </div>
            </div>
          </Form.Item>

          {/*{isFormPage && (*/}
          {/*  <p style={{ marginTop: "15px", fontStyle: "italic" }}>*/}
          {/*    Maximum {maxLength} words*/}
          {/*  </p>*/}
          {/*)}*/}
          <div className={style.bottomText}>
            Climate Finance Playground can make mistakes. Check important
            information.
          </div>
        </Form>
      </div>
    </>
  );
};

//export default memo(PlaygroundForm);
export default PlaygroundForm;
