import React, { useState } from 'react';
import styles from './promptpanel.module.scss';
import { EVALUATE_SUBTABS } from '@utils/json-data';
import FolderIcon from '@assets/componentIcons/FolderIcon';
import Cross2 from '@assets/icons/cross2.svg';
import Down from '@assets/icons/down.svg';
import GreenCheck from '@assets/icons/greenCheck.svg';
import { AdminPanelApi } from '@api/admin-panel';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { sectionsMap as climateRationaleSectionsMap } from '@components/pages/project/rational-advisor-page';
import { sectionsMap as theoryOfChangeSectionsMap } from '@components/pages/project/theory-of-change-page';
import { sectionsMap as renewableProjectSectionsMap } from '@components/pages/project/advisor-page';
const Config = {
  climate_rationale: {
    functionality: 'climate rationale',
    label: 'Climate Rationale Advisor',
    tabs: {
      Identification: ['1.1 Project Effectiveness'],
      Response: ['2.1 Potential Barriers'],
      Alignment: [],
      'Monitoring and Evaluation': [],
    },
    sectionsMap: climateRationaleSectionsMap,
  },
  theory_of_change: {
    functionality: 'theory of change',
    label: 'Theory of Change',
    tabs: {
      'Goal Statement Review': [],
      'Alignment Checker': [],
      'Barriers and Risks': [],
      'Assumptions': [],
      'Notional Log Frame': [],
    },
    sectionsMap: theoryOfChangeSectionsMap
  },
  draft_cn: {
    functionality: 'concept note',
    label: 'Draft a Concept Note',
    tabs: {
      'B.1 Context and Baseline': [
        'B.1.1 Project Justification',
        'B.1.2 Country Ownership',
      ],
      'B.2 Project/Programme Description': [
        'B.2.1 Theory of Change',
        'B.2.2 Accredited Entity and Project Monitoring',
      ],
      'B.3 Expected Results: Impact & Paradigm Shift': [
        'B.3.1 Sustainable Development Potential',
        'B.3.2 Needs, Ownership & Efficiency Potential',
      ],
      'B.4 Engagement Among the NDA, AE and/or other': [],
      'C.1 Indicative Finance': [],
      'C.2 Justification of Funding': [],
      'C.3 Sustainability and Replicability': [],
    },
  },
  evaluate_cn: {
    functionality: 'concept note evaluator',
    label: 'Evaluate a Concept Note',
    tabs: EVALUATE_SUBTABS,
  },
  renewable_project: {
    functionality: 'project structuring',
    label: 'Renewable Project Structuring',
    tabs: {
      'Project Identification and Scope': [],
      'Feasibility and Site Assesment': [],
      'Financial and Economic Analysis': [],
      'Regulatory and Compliance': [],
      'Project Implementation and Timeline': [],
      'Operations Maintenance and Monitoring': [],
    },
    sectionsMap: renewableProjectSectionsMap
  },
  full_proposal: {
    functionality: '',
    label: 'Full Proposal Evaluator',
    tabs: {},
  },
};

const PromptPanel = () => {
  const [page, setPage] = useState('index');
  const [prompt, setPrompt] = useState({
    key: '',
    section: '',
    subSection: '',
  });
  const [loadingPrompt, setLoadingPrompt] = useState(false);
  const [savingPrompt, setSavingPrompt] = useState(false);
  const [textValue, setTextValue] = useState('');
  const [updatePromptSuccess, setUpdatePromptSuccess] = useState(null);
  const [expandedList, setExpandedList] = useState(
    Object.keys(Config).reduce((acc, val) => {
      acc[val] = val === 'evaluate_cn';

      return acc;
    }, {})
  );

  const toggleExpandedList = (key) => {
    const list = { ...expandedList };

    list[key] = !list[key];

    setExpandedList(list);
  };
  const shouldButtonsBeDisabled = loadingPrompt || savingPrompt;

  const handlePromptClick = async (key, section, subSection = undefined) => {
    setPrompt({
      key,
      section,
      subSection,
    });
    setPage('editPrompt');
    setLoadingPrompt(true);

    const targetSection = subSection || section;
    try {
      const baseConfig = Config[key];
      const section = baseConfig.sectionsMap
        ? baseConfig.sectionsMap.find(el => el.text === targetSection).section
        : targetSection.split(' ')[0].split('.').join('_');

      const data = await AdminPanelApi.getPrompt({
        functionality: baseConfig.functionality,
        section,
      });

      setLoadingPrompt(false);
      setTextValue(data.prompt);
    } catch (error) {
      setTextValue(
        `Error fetching PROMPT for section "${section}", please do not attempt saving. Contact tech team.`
      );
    } finally {
      setLoadingPrompt(false);
    }
  };

  const handleBackClick = () => {
    setPrompt({
      key: '',
      section: '',
      subSection: '',
    });
    setPage('index');
    setLoadingPrompt(false);
    setTextValue('');
    setUpdatePromptSuccess(null);
  };

  const handleUpdatePromptClick = async () => {
    setSavingPrompt(true);

    try {
      const targetSection = prompt.subSection || prompt.section;
      const { key } = prompt;
      const baseConfig = Config[key];

      const section = baseConfig.sectionsMap
        ? baseConfig.sectionsMap.find(el => el.text === targetSection).section
        : targetSection.split(' ')[0].split('.').join('_');

      const data = await AdminPanelApi.updatePrompt({
        functionality: Config[key].functionality,
        section,
        prompt: textValue,
      });
      setUpdatePromptSuccess(true);
      setSavingPrompt(false);
    } catch (error) {
      setUpdatePromptSuccess(false);
    } finally {
      setSavingPrompt(false);
    }
  };

  const handleDismissErrorClick = () => {
    setUpdatePromptSuccess(null);
  };

  let editPromptContent = null;
  if (Config[prompt.key]) {
    editPromptContent = (
      <div className={styles.editPromptContent}>
        <div className={styles.editPromptHeader}>
          <div>
            Prompts{` > `}
            {Config[prompt.key].label}
            {` > `}
            {prompt.section}
            {prompt.subSection ? ` > ${prompt.subSection}` : null}
          </div>
          <div>
            {updatePromptSuccess === true ? (
              <div className={styles.updatePromptSuccess}>
                <img src={GreenCheck} className={styles.greenCheck} />
                <span>Prompt Saved successfully</span>
                <img
                  src={Cross2}
                  alt='Dismiss'
                  className={styles.cross}
                  onClick={handleDismissErrorClick}
                />
              </div>
            ) : null}
            {updatePromptSuccess === false ? (
              <div className={styles.updatePromptSuccess}>
                <span>Error saving prompt!!</span>
                <img
                  src={Cross2}
                  alt='Dismiss'
                  className={styles.cross}
                  onClick={handleDismissErrorClick}
                />
              </div>
            ) : null}
          </div>
        </div>
        <textarea
          value={textValue}
          onChange={(event) => setTextValue(event.target.value)}
          placeholder={loadingPrompt ? 'Loading...' : ''}
          disabled={loadingPrompt}
          rows={20}
          style={{ width: '100%' }}
        />
        <div className={styles.editPromptFooter}>
          <button
            className={styles.savePrompt}
            onClick={handleUpdatePromptClick}
            disabled={shouldButtonsBeDisabled}
          >
            {savingPrompt ? 'Saving...' : 'Save Prompt'}
          </button>
          <button
            className={styles.goBack}
            onClick={handleBackClick}
            disabled={shouldButtonsBeDisabled}
          >
            {'< '}Back to Prompts
          </button>
        </div>
      </div>
    );
  }

  const indexContent = (
    <div>
      <div className={styles.topContainer}>
        <div>Prompts</div>
      </div>
      <div className={styles.fnList}>
        {Object.keys(Config).map((key) => (
          <div className={styles.fnListItem}>
            <div className={styles.fnListItemTitle}>
              <FolderIcon />
              {Config[key].label}
              <div
                className={styles.fnListItemTitleExpand}
                onClick={() => toggleExpandedList(key)}
              >
                {expandedList[key] ? <UpOutlined /> : null}
                {!expandedList[key] ? <DownOutlined /> : null}
              </div>
            </div>
            <div
              className={styles.fnListItemPrompts}
              style={{ display: expandedList[key] ? 'block' : 'none' }}
            >
              {Object.keys(Config[key].tabs).map((section) => (
                <div className={styles.group}>
                  <div
                    className={styles.label}
                    onClick={(e) => handlePromptClick(key, section)}
                  >
                    {section}
                  </div>
                  {Config[key].tabs[section].map((subSection) => (
                    <div
                      className={styles.subLabel}
                      onClick={(e) =>
                        handlePromptClick(key, section, subSection)
                      }
                    >
                      {subSection}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      {page === 'index' ? indexContent : null}
      {page === 'editPrompt' ? editPromptContent : null}
    </div>
  );
};

export default PromptPanel;
