import welcomeLogo from "@assets/images/welcomeLogo.png";
import { useStore } from "@stores/root-store";
import { catchError } from "@utils/common-functions";
import { constRoute } from "@utils/route";
import { Button, Form, Input, Spin } from "antd";
import { observer } from "mobx-react";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import style from "./style.module.scss";

let isFormSubmitting = false
const VerifyEmail = observer(() => {
  const [form] = Form.useForm();
  const signupData = JSON.parse(localStorage.getItem("signupPayload"));
  const navigate = useNavigate();
  const {
    user: {
      isLoadingSignup,
      onSignUpUser,
      verificationCode,
      loadUserInfo,
      onSendEmailVerification
    }
  } = useStore(null);
  const onResendCode = async () => {
    await onSendEmailVerification({
      email: signupData?.email,
      name: signupData?.name
    });
  };
  const onFormSubmit = async values => {
    if (isFormSubmitting) {
      console.log('Form is already being submitted')
      return
    }
    if (values.code === verificationCode) {
      isFormSubmitting = true
      console.log(signupData);
      const res = await onSignUpUser(signupData);
      localStorage.removeItem("signupPayload");
      isFormSubmitting = false
      if (res?.jwt_token) {
        localStorage.setItem('plan', signupData.plan.toLowerCase());
        localStorage.setItem('email', signupData.email);
        localStorage.setItem("token", res?.jwt_token);
        localStorage.setItem('plan', signupData.plan.toLowerCase());
        localStorage.setItem('email', signupData.email);
        setTimeout(() => {
          loadUserInfo().then(data => {
            if (
              data?.data?.error?.includes("Invalid token") ||
              data?.data?.error?.includes("Token has expired")
            ) {
              catchError(data, "loadUserInfo");
              navigate(`${constRoute.login}`);
            } else {
              localStorage.setItem("showInfoModal", "true");

              console.log("navigateeee");

              navigate(`${constRoute.home}`);
            }
          });
        }, 1000);
      } else {
        navigate(constRoute?.signup);
      }
    }
  };

  const validateMessages = {
    required: "Code is required!"
  };

  return (
    <div className={style.mainContainer}>
      <div className={style.container}>
        <div className={style.headingWrapper}>
          <img src={welcomeLogo} alt="janus-logo" className={style.janusLogo} />
          <h2 className={style.forgotPassword}>Verification Code </h2>
          <p>
            We have sent a verification code to the email
            <span className={style.emailSpan}>
              {" " + signupData?.email}.
            </span>{" "}
            Please check your inbox and also your spam folder for verification
            email.
          </p>
        </div>
        <Form
          className={style.formData}
          form={form}
          onValuesChange={e => {}}
          autoComplete="false"
          onFinish={onFormSubmit}
          validateMessages={validateMessages}
          layout="vertical"
        >
          <Form.Item
            name={"code"}
            label="Verification Code"
            rules={[
              {
                required: true,
                message: "Please Enter a valid code"
              }
            ]}
          >
            <Input
              type="number"
              className={style.emailInput}
              placeholder={"Enter code"}
            />
          </Form.Item>
          <div className={style.loginWrraper}>
            <Button className={style.resendClickBtn} htmlType="submit">
              {(isLoadingSignup && <Spin />) || "Continue"}
            </Button>
          </div>
        </Form>
        <span className={style.resendText}>
          Didn’t receive the email link?
          <button
            onClick={onResendCode}
            className={`${style.resendBtn} ${style.aLink}`}
          >
            Click to resend
          </button>
        </span>
        <span className={style.resendText}>
          Back to
          <span
            className={style.aLink}
            onClick={() => navigate(constRoute?.login)}
          >
            {" "}
            Log In
          </span>
        </span>
      </div>
    </div>
  );
});

export default memo(VerifyEmail);
